body {
  margin: 0;
  font-family: Lato, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.brands {
  text-align: center;
}

.brands a {
  display: initial !important;
}

.section-gangahoy {
  width: 100%;
  max-width: 300px;
  border-radius: 4px;
  padding: 1.2rem 1.5rem;
  color: #fff;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 1rem 0.5rem;
}

.section-gangahoy h2 {
  font-size: 18px;
  margin-bottom: 0px;
}

.section-gangahoy p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
}

.section-principal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1140px;
  margin: 0 auto;
}

.shopping-cart-number {
  position: absolute;
  font-size: 10px;
  width: 17px;
  height: 17px;
  background-color: red;
  display: block;
  border-radius: 50%;
  line-height: 17px;
  color: white;
  right: 2.8rem;
  top: 3px;
  font-weight: bold;
}

.btn-quantity {
  border: 1px solid rgba(0,0,0,0.2);
  padding: 0px 8px;
  border-radius: 3px;
  margin-right: 8px;
}


button {
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: bold;
}